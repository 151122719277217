@fontFamilyName2: 'nunito';
@fontFamilyDir2: '../assets/fonts/nunito';
@fontFamilyPathBaseName2: 'nunito';

// EXTRA-BOLD -800

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-extrabold.woff')
        format('woff');
    font-weight: 800;
    font-style: normal;
}

// BOLD - 700

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-bold.woff')
        format('woff');
    font-weight: 700;
    font-style: italic;
}

// SEMI-BOLD - 600

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-semibold.woff')
        format('woff');
    font-weight: 600;
    font-style: normal;
}

// REGULAR - 400 - normal

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-regular.woff')
        format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: '@{fontFamilyName2}';
    src: url('@{fontFamilyDir2}/@{fontFamilyPathBaseName2}-italic.woff')
        format('woff');
    font-weight: 400;
    font-style: italic;
}
