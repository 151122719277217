


@pieColorSet : {
    color-0: @BE-blue;
    color-1: @BE-navy-grey;
    color-2: @BE-blue-logo;
    color-3: @BE-dark-green;
    color-4: @BE-green;
    color-5: @BE-green-logo;
    color-6: @BE-grey-logo;
    color-7: @BE-pro;
    color-8: @greyLight;
    color-9: @yellow;
}

.main.dashboard {
    .label {
        line-height: 125%;
    }

    .dashboard__header .date-range__wrapper {
        font-size: 1.5rem;
    }
}