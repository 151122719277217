//POSITIONS

@media @sdi-small {

    .header,
    .main,
    .footer {
        position: relative;
        width: calc(~'100vw - 4 * @{margin-mob}');
        height: auto;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        margin: 0 2 * @margin-mob;
        padding: 0;
    }

    .main {

        .map-wrapper,
        .map-legend,
        .feature-view.config {
            position: relative;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            width: calc(~'100vw - 4 * @{margin-mob}');
            height: auto;
            margin: 0;
            padding: 0;
        }

        .map-wrapper {
            height: calc(~'100vw - 4 * @{margin-mob}'); // make a square map
        }
    }

    .vertical-split {
        position: relative;
        height: 200vw !important;
        max-height: calc(~'100vh - 4 * @{margin-mob}') !important; //we get map + table on fullscreen
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        display: flex;
        flex-direction: column-reverse;

        .map-wrapper {
            height: auto;
            bottom: 50% !important;
            margin-bottom: 0 !important;
        }
    }

    h1 {
        font-size: 250%;
    }
}

// HEADER

@media @sdi-small {
    body[lang='fr'] .brand-name {
        width: calc(~'100vw - 4 * @{margin-mob}');
        max-width: calc(~'100vw - 4 * @{margin-mob}');
    }

    body[lang='nl'] .brand-name {
        width: calc(~'100vw - 4 * @{margin-mob}');
        max-width: calc(~'100vw - 4 * @{margin-mob}');
    }

    .header {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        // flex-wrap: wrap;
        // justify-content: space-between;
        margin-bottom: @margin-mob;
        border-bottom: @borderLight;
        padding-bottom: @margin-mob * 2;

        .logo-link {
            width: 100%;
        }

        .brand-logo,
        .brand-logo>div {
            height: 100vw * 0.095; //+/- image ratio
            max-height: 35px;
        }

        .brand-logo {
            border-bottom: @borderLight;
            margin-bottom: @margin-mob * 2;
            width: 100%;
        }

        .app-title,
        .header-toolbar,
        .app-listwrapper {
            position: relative;
            width: auto;
            min-height: 20px;
            height: auto;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }


        .app-title {
            margin-left: 0;
            font-size: 1.1rem;
        }

        .app-listwrapper {
            order: 2;
            margin: auto;
            border-top: @borderLight;
            padding-top: @margin *0.66;
            width: 100%;
            margin-top: @margin * 0.33;

            .navigate {
                margin: 0;
            }
        }

        .header-toolbar {
            width: 100%;
            display: flex;
            justify-content: end;
            margin-top: @margin*0.5;
            padding-top: @margin *0.75;
            border-top: @borderLight;

            >div {
                flex-shrink: 0;
            }

            .user {
                width: 100%;

                .user-details {
                    margin-right: 0;
                }

                .navigate.login {
                    margin-left: auto;
                }
            }

            .tools {
                position: absolute;
                top: -2em;
            }
        }

        .app-select .selected {
            padding-left: 0px;
        }
    }
}

// FOOTER

@media @sdi-small {
    .footer {
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: @margin;
        margin-top: @margin;
        border-top: @borderLight;

        .footer-infos {
            width: 100%;
            display: flex;
            flex-direction: column;

            a:first-child {
                margin-bottom: @margin * 0.5;
            }
        }

        .csac-credits {
            left: 0;
            margin: 0;
            margin-top: @margin * 0.5;
        }
    }
}

// MAP

@media @sdi-small {
    .main {
        .map-wrapper {
            margin-bottom: @margin-mob * 2;

            .tool.geocoder {
                .absolute(0, initial, initial, 0);
                z-index: 10;
                min-height: 25px;
                border-bottom-color: @borderLightColor;
                border-top-color: @bodyBgColor;
                max-width: calc(~'100vw - 4 * @{margin-mob} - 30px');

                .adress-result span {
                    min-height: 20px;
                    height: auto;
                    padding: 2px 0;
                }
            }
        }

        .switcher.infos {
            .absolute(0, 0, initial, initial);
        }

        .map-legend {
            .legend-main {
                padding: 0;
            }
        }
    }
}

// TABLE

@media @sdi-small {
    div.infinite-table {
        .table-toolbar {
            flex-direction: column;
            align-items: flex-start;

            >div {
                margin-bottom: @small-margin;
            }

            .table-title {
                max-width: calc(~'100% - 30px');
            }

            .btn-close {
                .absolute(2 * @small-margin, @small-margin, initial, initial);
            }
        }
    }
}