//MAP NAVIGATOR

@media @sdi-small {
    .map-navigator {
        width: 100%;
        border: 0px;
        padding: 0;
        display: block;

        h1 {
            margin-bottom: @margin;
        }

        .content {
            overflow: hidden;
            padding-right: initial;
        }

        .sidebar {
            width: 100%;
            height: auto;
            margin-bottom: @margin;

        }

        .app-list__wrapper .list {
            flex-direction: column;
            align-items: center;
            text-align: center;

            .app-item {
                width: 90%;
                margin: auto;
                align-items: center;

                * {
                    text-align: center;
                }

                .app-img {
                    display: flex;
                    justify-content: center;
                }

            }
        }

        .map-list__wrapper .map-list {
            .map-tile {
                min-width: calc(~"50% - @{margin}*0.5");

                .map-tile-img {
                    margin: 0 auto;
                }
            }
        }
    }
}

// MAP VIEWER 

@media @sdi-small {
    .main {
        display: flex;
        flex-direction: column;

        .map-wrapper {
            order: 1
        }

        .switcher {
            order: 2
        }

        .sidebar__wrapper {
            order: 3;
        }
    }
}


//MAP WIDGETS

@media @sdi-small {


    .main .map-wrapper {
        height: calc(~'100vw - 4 * @{margin-mob} + 2em + 2 * @{small-margin}'); // make a square map + geocoder & margin height 
    }

    .main .switcher {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 0px;
        border: none;
        border-bottom: 1px solid #000;
        overflow-x: auto;
        overflow-y: hidden;

        .switch-item {
            width: auto;
        }

        .picto:before {
            font-size: 15px;
        }
    }

    .main .geocoder-wrapper {
        .absolute(@small-margin, @small-margin, initial, @small-margin);

        .search-wrapper {
            width: 100%;
        }

        .search {
            width: inherit;
        }
    }
}

//LEGEND

@media @sdi-small {
    .datas-wrapper .layer-actions {
        width: auto;
        font-size: 1rem;
        height: calc(~'1rem + 5px');
        margin: 0;

        .visible:before,
        .hidden:before,
        .table:before {
            height: calc(~'1rem + 5px');
            width: calc(~'1rem + 5px');
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin-right: 5px;
        }
    }
}

@media @sdi-small {
    .main .sidebar__wrapper {
        position: relative;
        margin-top: 20px;
        padding: 0;
        padding-bottom: 50px;
        top: initial;
        right: initial;
        bottom: initial;
        left: initial;

        &.feature-info-page {
            .actions {
                display: flex;
            }

            .tip-desktop {
                display: none;
            }

            .group .feature-info-wrapper {
                margin-left: 0px;
                margin-right: 0px;
            }
        }

    }
}

// TOOLS INFOS

@media @sdi-small {
    .tool-widget {
        position: relative;
        width: 100%;
        border: 0px;
        top: initial;
        right: initial;
        bottom: initial;
        left: initial;
        z-index: 2;
    }
}