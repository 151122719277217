// header logos

html[lang='fr'] .brand-logo .brand-name, html[lang='nl'] .brand-logo .brand-name  {
    background-image: url('assets/imgs/header-rimdir.png');
    width: 800px;
    height: 65px;
}

@media screen and (max-width: 1600px) {
    html[lang='fr'] .brand-logo .brand-name, html[lang='nl'] .brand-logo .brand-name  {
        width: 650px;
        height: 55px;
    }
    .header .app-listwrapper {
        left: 800px;
        right: unset;
    }
}

@media screen and (max-width: 1250px) {
    .header {
        font-size: 100%!important;
    }

    html[lang='fr'] .brand-logo .brand-name, html[lang='nl'] .brand-logo .brand-name  {
        width: 500px;
        height: 55px;
    }
    .header .app-listwrapper {
        left: 600px;
        right: unset;
    }
}

@media screen and (max-width: 1000px) {
   .app-title {
      display: none!important;
   }
   .header .app-listwrapper {
      left: 500px;
      right: unset;
   }
}


.header {
    height: 60px;
    font-size: 110%;

    a {
        box-shadow: initial;
    }

    .header-toolbar {
        top: 12px;
    }

    .app-title {
        color: @BE-black!important;
    }

    .links {
        a {
            color: @textColor!important;
            margin-right: 20px;
        }
    }

    .app-listwrapper {
        position: absolute;
        top: 12px;
    }

    .header-toolbar .user {
        padding-right: 10px;
    }
}

a {
    color: @blue !important;
    box-shadow: inset 0 -1px 0 0 @blue;
    text-decoration: none !important;
    border: 0px !important;
    &:hover {
        box-shadow: inset 0 -2px 0 0 @blue;
    }
    &:focus-visible {
        outline: 3px dotted @blue !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'nunito', Helvetica, Arial, Verdana, sans-serif;
    font-weight: @fwExtraBold;
    line-height: 1.05;
}

h1 {
    font-size: 1.8rem;
    color: @BE-blue-logo;
    line-height: 1.2;
}
h2 {
    font-size: 1.4rem;
    color: @BE-green;
}
h3 {
    font-size: 1.2rem;
    color: @headingColor;
}
h4 {
    font-size: 1rem;
    color: @headingColor;
}
h5,
h6 {
    font-size: 1rem;
    color: @headingColor;
}

.footer {
    .footer-infos {
        display: none;
    }
    height: 100px;
    background-image:
        url('./assets/imgs/EU_Enabel.png'),
        url('./assets/imgs/sher-logo.jpg'),
        url('./assets/imgs/Mauritania_logos.png'),
    ;
    background-repeat: no-repeat;
    background-position: 40%, 54%, 70%;
    background-size: 12%, 5%, 10%;
    margin: 0px auto;
    max-width: 1400px;
    align-items: center;
}

.main {
    bottom: 100px;
    top: 60px;
}

.wms-legend-wrapper {
    display: none;
}

.print-info {
    display: none;
}

.header {
    .documentation {
        .contact {
            display: none;
        }
    }
}

.tools {
    display: none;
}

.sidebar-switch {
    display: none;
}

.pitch {
    font-weight: normal;

}

h1.pitch {
    font-size: 1.7rem!important;
}

// disable dashboard img
.edito {
    .illu__wrapper, .edito__title {
        display: none;
    }
}

// hide NL in alias table

.alias-widget {
    .alias-table {
        .table-header {
            div.table-cell:nth-child(3) {
                display: none;
            }
            div.table-cell:nth-child(2) {
                border-right: unset;
            }
        }
        .table-row {
            div.table-cell:last-child {
                display: none;
            }
        }
    }
    .form {
        div.form-replace:nth-child(2) {
            display: none;
        }
    }
}


