header,
header.header {
    .app-title {
        text-transform: uppercase;
        font-family: 'nunito';
        font-weight: @fwExtraBold;
        color: @BE-navy-grey;
    }
    button.navigate,
    button.head-label,
    .navigate.documentation,
    .navigate.login,
    button.lang-switch {
        &:before {
            color: @BE-green-logo !important;
        }
        &:hover:before {
            color: @blue;
        }
    }
}
